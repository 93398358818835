<template>
  <div
    class="form-control select-input-container"
    ref="formControl"
    :class="{ active: showDrop, 'form-control-multiple': isInSelectMultiple }"
    @click.self="setDrop(true)"  
    tabindex="0"
  >
      <!-- <input ref="disabledCheckInput" style="position:absolute; left:0; top:0; width:0; height:0; opacity:0" /> -->
      <input
        v-if="showDrop && autocomplete"
        ref="inputbox"
        class="select-input-content"
        :placeholder="placeholder"
        v-model="currentKey"
        @blur="blur"
        @keydown.esc="setDrop(false)"
        @keydown.enter.prevent="select(itemList[hoverIndex])"
        @keydown.down.prevent="arrowDown"
        @keydown.up.prevent="arrowUp"
        @keyup="getItems"
        @input="handleInput"
        type="text"
        autocomplete="off"
        :disabled="isDisabled"
      />
      <input v-else type="text" style="position:fixed; top:-10px; left:-10px; width:0px; height:0px; opacity:0;"
        ref="inputbox"
        @focus="setDrop(true)"
        @blur="blur"
        @keydown.esc="setDrop(false)"
        @keydown.enter.prevent="select(itemList[hoverIndex])"
        @keydown.down.prevent="arrowDown"
        @keydown.up.prevent="arrowUp"
        />
      <span v-if="!autocomplete || !showDrop" class="select-input-content" @click="$refs.inputbox.focus()">
        <slot></slot>
      </span>
      <svg
        v-show="!showDrop"
        @click="setDrop(true)"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-chevron-down icon"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        />
      </svg>
      <svg
        v-show="showDrop"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-chevron-up icon"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>

      <div
        v-if="showDrop"
        ref="dropPanel"
        class="select-input-drop"
        @blur="blur"
        :style="'min-width:' + minWidth + 'px' + ';' + 'max-width:' + maxWidth + 'px'+ ';' + 'max-height:' + maxHeight + 'px'+ ';'"
        tabindex="0">
        <div v-if="newRecordVisible" class="select-input-item addRecord" @mouseover="hoverIndex = -1" @click="addRecord">
          + {{ translate(addRecordModalTitle) }}
        </div>
        <div
          v-for="(i, index) in itemList"
          :key="i.Id"
          @mousedown="select(i)"
          class="select-input-item"
          :class="{ hover: hoverIndex == index }"
          @mousemove="hoverIndex = index">
          <slot name="item" :item="i"></slot>
        </div>
        <div
          v-if="isFirstSearch"
          class="text-muted text-center"
          style="padding: 20px 0; font-style: italic">
          <small>{{ nodataText || translate('noData') }}</small>
        </div>
        <div
          v-else-if="!itemList || itemList.length == 0"
          class="text-muted text-center"
          style="padding: 20px 0; font-style: italic">
          <small>{{ nodataText || translate('noData') }}</small>
        </div>
      </div>
      <AddRecordModal v-if="addRecordModalVisible" :modalTitle="addRecordModalTitle" :saveStatus="recordSaveStatus" @saveRecord="saveRecord" @close="closeAddRecordModal"></AddRecordModal>
  </div>
</template>
<script>
import axios from "axios";
import AddRecordModal from "./AddRecordModal.vue";

export default {
  components:{
    AddRecordModal
  },
  props: {
    maxWidth: {
      type: String,
      default: '700'
    },
    maxHeight : {
      type : String,
      default: '120'
    },
    items: {
      type: Array,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    datasource: {
      type: String,
    },
    filterFunction: {
      type: Function
    },
    nodataText: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isInSelectMultiple: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {},
    index : {
      type: Number,
      default: 0
    },
    addRecordVisible: {
      type : Boolean,
      default: false
    },
    recordType: {
      type: String,
    },
    addRecordModalTitle:{
      type : String,
      default: 'newRecord'
    },
    isFirstSearch:{
      type: Boolean,
      default: false
    }
    
  },
  data() {
    return {
      showDrop: false,
      minWidth: 200,
      currentKey: "",
      previousKey: "",
      hoverIndex: -1,
      dynamicItems: null,
      blurTimeout: null,
      cancelBlur: false,
      addRecordModalVisible: false,
      recordSaveStatus: "notSaved",
    };
  },
  computed: {
    itemList() {
      return this.dynamicItems ?? this.items;
    },
    newRecordVisible(){
      return this.addRecordVisible;
    }
  },
  methods: {
    saveRecord(value){
      this.recordSaveStatus = 'saving'
      var objectId = null
      var postData = {}
      axios.get(`/${this.recordType}/get/${window.emptyObjectId}?kind=true`).then(x => {
        objectId = x.data.Id;
        if(objectId != null){
          if(this.recordType == 'department'){
            postData = {
              Id: objectId,
              IsActive: true,
              Name: value,
              Description: null,
              Email: null,
              ManagerIds: null,
              UserIds: []
            }
          }else{
            postData = {
              Id: objectId,
              IsActive: true,
              Name: value
            }
          }
         
          axios.post(`/${this.recordType}/post/${objectId}`,postData).then(() => {
            this.recordSaveStatus = 'saved'
            this.addRecordModalVisible = false
            this.$emit('addRecordSuccess',x.data, this.recordType)
            this.recordSaveStatus = 'notSaved'
          }).catch(x=>{
            console.log(this.recordType + " kaydedilirken sorun oldu", x)
            this.recordSaveStatus = 'notSaved'
            window.processErrorResponse(x,this)
          })
        }
      }).catch(x => {
        console.log("id getirilirken sorun oldu", x)
        this.recordSaveStatus = 'notSaved'
        window.processErrorResponse(x,this)
      })
    },
    closeAddRecordModal(){
      this.addRecordModalVisible = false;
    },
    addRecord(){
      this.showDrop = false
      this.addRecordModalVisible = true;
    },
    handleInput(){
      this.$emit('input', this.currentKey)
    },
    focus(){
      this.showDrop = true;
      if(this.isDisabled)
        this.showDrop = false;
      this.$refs.inputbox.focus();
    },
    blur(event){
      if (!this.$refs.formControl.contains(event.relatedTarget)) {
        if(this.cancelBlur) {
          this.cancelBlur = false;
          this.focus();
          return;
        }

        this.setDrop(false);
      }
    },
    select(item) {
      this.currentKey = null;
      // console.log('Selected Item: ', item);
      this.$emit("change", item, this.index);
      setTimeout(this.getItems, 10);

      if(this.isInSelectMultiple) {
        this.cancelBlur = true;
        this.focus();
        if (this.hoverIndex >= this.itemList.length) this.hoverIndex = this.itemList.length - 1;
      } else {
        this.setDrop(false);
      }      
    },
    setDrop(state) {
      // if(this.$refs.disabledCheckInput.disabled)
      //   return false;

      if (state && !this.isDisabled) {
        this.getItems();
        this.minWidth = this.$refs.formControl.offsetWidth;
        if (this.autocomplete) {
          window.setTimeout((x) => {
            x.$refs.inputbox.focus();
          }, 20, this);
        }
      }

      this.showDrop = state;
      if(this.isDisabled)
        this.showDrop = false;
    },
    getItems() {
      if(this.datasource && this.currentKey && this.currentKey.length > 0 && this.currentKey != this.previousKey) {
        this.previousKey = this.currentKey;
        axios({
          method: "get",
          url: this.datasource,
          params: { name: this.currentKey },
          headers: {
                "Access-Control-Allow-Origin": "*",
                 "Access-Control-Allow-Headers":"*",
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
              }
        }).then((x) => {
          let aa =JSON.parse(x.data);
          this.dynamicItems = aa.Data.Items;
        });
      } else if(this.filterFunction) {
        this.dynamicItems = this.items?.filter(x => this.filterFunction(x, this.currentKey || ''));
      }

      this.$forceUpdate();
    },
    arrowDown() {
      this.hoverIndex = this.hoverIndex + 1;
      if (this.hoverIndex >= this.itemList.length) this.hoverIndex = 0;

      this.scroll();
    },
    arrowUp() {
      this.hoverIndex = this.hoverIndex - 1;
      if (this.hoverIndex < 0) this.hoverIndex = this.itemList.length - 1;
      this.scroll();
    },
    scroll() {
      var node = this.$refs.dropPanel.childNodes[this.hoverIndex];
      var top = node.offsetTop;

      if (top < this.$refs.dropPanel.scrollTop) {
        this.$refs.dropPanel.scrollTop = top;
      } else if (
        top + node.offsetHeight >
        this.$refs.dropPanel.scrollTop + this.$refs.dropPanel.offsetHeight
      ) {
        this.$refs.dropPanel.scrollTop =
          top - this.$refs.dropPanel.offsetHeight + node.offsetHeight;
      }

      //console.log(this.$refs.dropPanel.scrollTop, top, this.$refs.dropPanel.offsetHeight);
    },
  },
};
</script>
<style scoped>
.icon {
  position: absolute;
  margin-left: -12px;
  margin-top: 5px;
  display: inline-block;
}

.select-input-container {
  cursor: default;
  white-space: nowrap
}

.select-input-container.active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select-input-item {
  padding: 1px 0px 1px 12px;
  overflow: hidden;
  cursor: default;
}

.select-input-item.hover {
  background: rgba(0, 0, 0, 0.075);
}

.select-input-drop {
  min-width: inherit;
  position: absolute;
  margin-left: -13px;
  z-index: 66666;
  background: #fff;
  /* max-height: 120px; */
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-top: 0;
  -webkit-box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.39);
  box-shadow: 2px 4px 8px 1px rgba(0, 0, 0, 0.39);
}


.select-input-content {
  display: inline-block;
  padding-right: 12px;
  width: 100%;
}

span.select-input-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input.select-input-content {
  z-index: 66667;
  position: relative;
  border: 0;
  background: transparent;
  top: -5px;
  padding-top: 5px;
}

input.select-input-content:focus {
  outline: none;
}

.form-control-multiple {
  border:1px solid transparent;
  border-top-color: inherit;
}

.addRecord{
  padding-top: 4px;
  padding-bottom: 4px;
}

.addRecord:hover{
  background: rgba(0, 0, 0, 0.075);
}

/* .form-control-multiple .select-input-drop {
  margin-left: 0px;
} */

/* .form-control-multiple input.select-input-content {
  left:0;
  top:0;
} */
</style>